import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import homeMap from '../views/homeMap/homeMap.vue'
import About from '../views/about.vue'
import Login from '../views/login/login.vue'
import LoginWechat from '../views/login/wechat.vue'
import LoginAlipay from '../views/login/alipay.vue'
import Confirm from '../views/confirm/confirm.vue'
import Wallet from '../views/wallet/wallet.vue'
import Result from '../views/result/result.vue'
import Order from '../views/order/order.vue'
import OrderDetails from '../views/order/orderDetails.vue'
import Setting from '../views/setting/setting.vue'
import Shop from '../views/shop/shop.vue'
import ShopDetails from '../views/shop/shopDetails.vue'
import Help from '../views/help/help.vue'
import WalletIn from '../views/wallet/wallet_in.vue'
import WalletOut from '../views/wallet/wallet_out.vue'
import WalletList from '../views/wallet/wallet_list.vue'
import GoogleMap from '../views/map/google-index.vue'
import DownloadApp from '../views/download/download'
import PaymentCard from '../views/payment/card'
Vue.use(VueRouter)

const routes = [{
  path: '/login',
  name: 'login',
  component: Login,
  meta: {
    ignoreAuth: true
  }
}, {
  path: '/',
  // name: 'homeMap',
  // component: homeMap,
  name: 'home',
  component: Home,
  meta: {
    ignoreAuth: true
  }
}, {
  path: '/home',
  name: 'home',
  component: Home,
  meta: {
    ignoreAuth: true
  }
}, {
  path: '/login_wechat',
  name: 'login_wechat',
  component: LoginWechat,
  meta: {
    ignoreAuth: true
  }
}, {
  path: '/login_alipay',
  name: 'login_alipay',
  component: LoginAlipay,
  meta: {
    ignoreAuth: true
  }
}, {
  path: '/about',
  name: 'about',
  component: About
}, {
  path: '/confirm',
  name: 'confirm',
  component: Confirm
}, {
  path: '/wallet',
  name: 'wallet',
  component: Wallet
}, {
  path: '/result',
  name: 'result',
  component: Result
}, {
  path: '/order',
  name: 'order',
  component: Order
}, {
  path: '/order/:orderId',
  name: 'order_details',
  component: OrderDetails
}, {
  path: '/setting',
  name: 'setting',
  component: Setting,
  meta: {
    ignoreAuth: true
  }
}, {
  path: '/shop',
  name: 'shop',
  component: Shop
}, {
  path: '/shop/details/*',
  name: 'shopDetails',
  component: ShopDetails
}, {
  path: '/help',
  name: 'help',
  component: Help
}, {
  path: '/wallet_in',
  name: 'wallet_in',
  component: WalletIn
}, {
  path: '/wallet_out',
  name: 'wallet_out',
  component: WalletOut
}, {
  path: '/wallet_list',
  name: 'wallet_list',
  component: WalletList
}, {
  path: '/google_map',
  name: 'google_map',
  component: GoogleMap,
  alias: '/map'
}, {
  path: '/download',
  name: 'download',
  component: DownloadApp
}, {
  path: '/payment_card',
  name: 'payment_card',
  component: PaymentCard
}]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.VUE_APP_DOMAIN,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  const token = store.state.token
  if (to.name === 'home') {
    if (to.query.qrcode) {
      store.commit('qrcode', to.query.qrcode)
      store.commit('zhcode', '')
    }
    if (to.query.zhcode) {
      store.commit('zhcode', to.query.zhcode)
      store.commit('qrcode', '')
    }
  }
  if (to.matched.some(r => r.meta.ignoreAuth) || token) {
    next()
  } else {
    next({
      path: '/login'
    })
  }
})

export default router
