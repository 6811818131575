<template>
  <page :title="i18n.title">
    <div class="shop-warp">
      <div class="shop-head">
        <div class="shop-head-evt" @click="onShopSearch"></div>
        <van-search
          v-model="searchVal"
          @search="onShopSearch"
          class="shop-head-search"
          :placeholder="i18n.search_station"
        />
      </div>
      <div class="shop-main" v-if="shopList.length">
        <div class="shop-item" v-for="item in shopList" :key="item.id">
          <shop-item :item="item"></shop-item>
        </div>
      </div>
      <result-msg
        v-else
        :loading="loading"
        :text="error"
        @click="loadList"
      ></result-msg>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
import { SHOP_LIST } from "../../apis/cabinet"
import ShopItem from "./shopItem.vue"
import ResultMsg from "../../components/result/msg.vue"
export default {
  components: {
    ShopItem,
    ResultMsg
  },
  computed: {
    ...mapState(["system"]),
    i18n() {
      return this.$t("shop")
    }
  },
  data() {
    return {
      error: "",
      loading: false,
      searchVal: "",
      shopList: []
    }
  },
  methods: {
    // 加载列表
    loadList() {
      this.getNearbyShop()
    },
    // 过滤｜搜索站点
    onShopSearch() {
      const val = this.searchVal
      if (!val) {
        return this.$toast(this.i18n.search_station_tips)
      }
      const searchRegex = (arr, v) => {
        let regex = new RegExp(v, "i")
        return arr.filter((item) => regex.test(item.shopName))
      }
      const shopList = this.shopList
      this.shopList = searchRegex(shopList, val)
    },
    // 获取周边站点
    getNearbyShop(param = {}) {
      const { query = {} } = this.$route
      let formdata = new FormData()
      formdata.append("coordType", "WGS－84")
      formdata.append("showPrice", true)
      formdata.append("zoomLevel", 4)
      formdata.append("lat", query.lat)
      formdata.append("lng", query.lng)
      // formdata.append("lat", 22.706337998877682)
      // formdata.append("lng", 113.78354094922543)
      this.$post(
        SHOP_LIST,
        formdata,
        (resp) => {
          this.$loading(false)
          this.shopList = resp.list
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        },
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
    }
  },

  mounted() {
    this.loadList()
  }
}
</script>
<style lang="less" scoped>
.shop-warp {
  min-height: 100vh;
  background-color: #fff;
}
.shop-head {
  position: relative;
  padding: 36px 56px;
  &-search {
    border: 2px solid #d5d5d5;
    border-radius: 20px;
    padding: 12px 24px;
    .van-field__control {
      font-size: 32px;
    }
    .van-search__content {
      background-color: transparent;
    }
  }
  &-evt {
    position: absolute;
    top: 36px;
    left: 56px;
    z-index: 9;
    height: 96px;
    width: 88px;
  }
}
.shop-main {
  padding: 0 56px 48px;
}
</style>
