<template>
  <!-- <page :title="i18n.title"> -->
  <div class="home">
    <div class="downloadAppBox" v-if="downloadApp">
      <div class="downloadAppBox-cancel">
        <span @click="downloadApp = false">X</span>
        <span style="width: 20%">
          <img src="../../assets/imgs/img_logo.png" alt="" />
        </span>
        <span style="margin-left: 20px">
          <div>EasyPower</div>
        </span>
      </div>
      <div class="downloadAppBox-btn btn" @click="onDownload">open</div>
    </div>
    <div class="google-map">
      <google-map
        :locationList="list"
        :latLng="latLng"
        @center="onCenter"
        @zoom="onZoom"
        @setItem="onSetItem"
        @onmap="show = false"
      />
      <div class="home-left" v-if="!isMap">
        <van-image
          :src="require('@/assets/imgs/icon_drawer.png')"
          width="1.12rem"
          @click="isMenuView = true"
        ></van-image>
      </div>
      <!-- <div class="home-right">
        <van-image :src="require('@/assets/imgs/icon_messages@2x.png')" width="1.5rem" @click="onClick"></van-image>
      </div> -->
      <!-- <div class="home-message">
      <div class="home-m-box">
        <van-image :src="require('assets/imgs/powerIn.png')" width="1.3rem"></van-image>
        <div class="m-box-title">You have orders in progress.</div>
        <div class="m-box-method">
          <span style="margin-right: 5px;">Method of return</span>
          <van-icon name="arrow"></van-icon>
        </div>
      </div>
    </div> -->
      <div class="home-bottom" v-if="!isMap">
        <div class="home-scan flex-c">
          <div class="nav-btn scan-box">
            <div class="scan-btn" @click="scanQr">
              <van-image
                :src="require('@/assets/imgs/icon_scan.png')"
              ></van-image>
            </div>
          </div>
          <div class="nav-btn" @click="goRouter('/shop' + shopQuery)">
            <van-image
              width=".8rem"
              :src="require('@/assets/imgs/icon_search@2x.png')"
            ></van-image>
            <p class="nav-label">{{ i18n.nav_search }}</p>
          </div>
          <div class="nav-btn" @click="goRouter('/help')">
            <van-image
              width=".8rem"
              :src="require('@/assets/imgs/icon_help@2x.png')"
            ></van-image>
            <p class="nav-label">{{ i18n.nav_help }}</p>
          </div>
        </div>
      </div>
      <transition name="fade">
        <NearbyShop
          v-if="item && show"
          :item="item"
          @click="onClick"
        ></NearbyShop>
        <!-- <div v-if="item && show" class="shop-info">
          <div class="shop-info-head">
            <div class="shop-title">
              <h3>{{ item.shopName }}</h3>
              <span
                :class="[
                  item.infoStatus === '在线' ? 'shop-tag-active' : '',
                  'shop-tag'
                ]"
                >{{ i18n[item.infoStatus] }}</span
              >
            </div>
            <a @click="goDetail"
              ><img src="@/assets/imgs/popup_btn_navigation.png" alt=""
            /></a>
          </div>
          <div class="shop-info-content">
            <div class="shop-logo">
              <img v-if="item.shopBanner" :src="item.shopBanner" alt="" />
              <img v-else src="../../assets/imgs/banner-home.jpg" alt="" />
            </div>
            <div class="shop-detail">
              <div class="shop-time">
                <span class="time">
                  <img src="../../assets/imgs/map.png" alt="" />
                  {{ item.shopAddress1 }}
                </span>
                <span class="time">
                  <img src="../../assets/imgs/distance.png" alt="" />
                  {{ item.distance }}
                </span>
                <span class="time">
                  <img src="../../assets/imgs/icon_time.png" alt="" />
                  {{ item.shopTime }}
                </span>
              </div>
              <div class="shop-op">
                <span class="free-num"
                  >{{ i18n.free }}：{{ item.freeNum }}</span
                >
                <span class="return-num"
                  >{{ i18n.return }}：{{
                    Number(item.batteryNum) - Number(item.freeNum)
                  }}</span
                >
              </div>
            </div>
          </div>
        </div> -->
      </transition>
    </div>
    <popup-warp
      v-model="isMenuView"
      dialogTransition="slide-left"
      style="background: #fff; height: 100vh"
    >
      <menu-side></menu-side>
    </popup-warp>
    <popup-warp
      v-model="ishomeSearch"
      position="bottom"
      class="search"
      style="border-radius: 60px 60px 0px 0px"
    >
      <div class="pop-box">
        <searchPlus v-model="searchText" @handleValue="reload"></searchPlus>
      </div>
    </popup-warp>
    <div class="fixed" v-if="isPickUpShow" @click="isPickUpShow = false">
      <ScanQr @scanQr="onScanQr" />
    </div>
  </div>
  <!-- </page> -->
</template>

<script>
import { mapState } from "vuex"
import GoogleMap from "@/views/map/google-map.vue"
import ScanQr from "@/views/scanQr/scanQr.vue"
import { debounce } from "../../utils/index"
import { SHOP_LIST, QR_CODE } from "../../apis/cabinet"
import { PopupWarp } from "@/components/popup"
import { MenuSide } from "@/components/menu"
import searchPlus from "@/components/searchPlus/searchPlus.vue"
import NearbyShop from "./nearbyShop.vue"
export default {
  components: {
    GoogleMap,
    PopupWarp,
    searchPlus,
    MenuSide,
    ScanQr,
    NearbyShop
  },
  data() {
    return {
      latLng: [40.4299976547387, -3.6925949275639396],
      zoomLevel: "4",
      list: [],
      item: null,
      show: false,
      isMenuView: false,
      ishomeSearch: false,
      isPickUpShow: false,
      searchText: "",
      isMap: false,
      downloadApp: false
    }
  },
  computed: {
    ...mapState(["user", "token", "browser", "qrcode", "system"]),
    i18n() {
      return this.$t("map")
    },
    i18nHome() {
      return this.$t("home")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    shopQuery() {
      const latLng = this.latLng || []
      return `?lat=${latLng[0]}&lng=${latLng[1]}`
    }
  },
  watch: {
    latLng() {
      this.lazyLoad(this)
    }
  },
  methods: {
    // type回调 shop_close 关闭弹框 shop_scan 扫码
    onClick(type) {
      if (type === "shop_close") {
        this.show = false
      } else if (type === "shop_scan") {
        this.show = false
        this.scanQr()
      } else {
        console.log("未开放")
        this.$toast(this.i18nMsg.notopen)
      }
    },
    goRouter(path) {
      this.$router.push(path)
    },
    reload(val) {
      console.log("reload===", val)
    },
    scanQr() {
      if (this.user && this.token) {
        this.isPickUpShow = true
      } else {
        this.$router.push("/login")
      }
    },
    onScanQr(qr) {
      console.log("qr====", qr)
      this.isPickUpShow = false
      let arr = qr.split("=")
      this.onScan(arr[1])
    },
    onScan(qrcode) {
      this.$loading(true)
      this.$post(
        `${QR_CODE}?id=${qrcode}`,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
          if (resp.zujieNum && resp.zujieNum > 0 && !resp.haveMifiBattery) {
            this.$confirm({
              title: this.i18nHome.msg_fail_title,
              content: `${this.i18nHome.msg_rentcount}`.format(resp),
              confirmText: this.i18nMsg.vieworder,
              cancelText: this.i18nMsg.cancel
            })
              .then(() => {
                this.$router.push("/order")
                this.$store.commit("qrcode", "")
              })
              .catch(() => {
                this.$store.commit("qrcode", "")
              })
          } else {
            this.$router.push("/home?cabinetshow=true")
            // this.isScan = true
            // this.isBtns = false
            // this.adVisible = true
          }
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        }
      )
    },
    getNearbyShop() {
      this.$loading(true)
      let formdata = new FormData()
      formdata.append("coordType", "WGS－84")
      formdata.append("mapType", "WGS－84")
      formdata.append("lat", this.latLng[0])
      formdata.append("lng", this.latLng[1])
      formdata.append("zoomLevel", this.zoomLevel)
      this.$post(
        SHOP_LIST,
        formdata,
        (resp) => {
          this.$loading(false)
          this.list = resp.list
        },
        (error) => {
          this.$loading(false)
          this.$toast(error)
        },
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
    },
    onZoom(zoomLevel) {
      // console.log('zoom', zoomLevel)
      this.zoomLevel = zoomLevel
      this.lazyLoad(this)
    },
    lazyLoad: debounce((vm) => {
      vm.getNearbyShop()
    }, 500),
    onCenter(latLng) {
      this.latLng = latLng
      this.lazyLoad(this)
    },
    onSetItem(val) {
      this.item = val
      this.show = true
    },
    onDownload() {
      let dowmloadUrl = process.env.VUE_APP_GOOGLE_PLAY
      if (this.system === "ios") {
        dowmloadUrl = process.env.VUE_APP_APP_STORE
      }
      if (dowmloadUrl) {
        window.open(dowmloadUrl)
      } else {
        this.$toast(this.i18nMsg.appoffline)
      }
    },
    // 加载扫码状态
    loadScanState() {
      const { qrcode } = this.$route.query
      const scanCode = sessionStorage.getItem("cdb_qrcode")
      if (!this.user && qrcode && scanCode !== qrcode) {
        sessionStorage.setItem("cdb_qrcode", qrcode)
      } else if (this.user && !qrcode && scanCode) {
        sessionStorage.removeItem("cdb_qrcode")
        this.onScan(scanCode)
      }
    }
  },
  created() {
    if (this.$route.query.isMap) {
      this.isMap = this.$route.query.isMap
    }
    if (this.$route.query.qrcode) {
      this.onScan(this.$route.query.qrcode)
    }
    let latitude = this.$route.query.latitude
    let longitude = this.$route.query.longitude
    if (latitude && longitude) {
      this.latLng = [+latitude, +longitude]
    }
  },
  mounted() {
    const { isScan } = this.$route.query
    this.loadScanState()
    // this.getNearbyShop()
    if (isScan) {
      this.scanQr()
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  /deep/.popup-warp {
    border-radius: 60px 60px 0px 0px;
  }

  .pop-box {
    // width: 100%;
    height: 1061px;
    background: #f5f5f5;
    box-shadow: 0px -10px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 60px 60px 0px 0px;
    padding: 50px 40px;
  }
}

.fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  // background: rgba(45, 45, 45, 0.5);
  height: 100%;
}

.home {
  position: relative;
  display: flex;

  .home-left {
    position: fixed;
    top: 96px;
    left: 40px;
    z-index: 1;
  }

  .home-right {
    position: fixed;
    top: 96px;
    right: 40px;
    z-index: 1;
  }

  .van-image /deep/ .van-image__img {
    width: 100%;
  }

  .home-message {
    width: 100%;

    position: fixed;
    top: 250px;
    margin: 0 auto;
    z-index: 1;

    .home-m-box {
      width: 90%;
      margin: 0 auto;
      height: 113px;
      background: #b2e6c7;
      border-radius: 18px 18px 18px 18px;
      display: flex;
      justify-content: flex-start;
      position: relative;

      .m-box-title {
        font-size: 28px;
        font-family: Work Sans-Medium, Work Sans;
        color: #00ab46;
        line-height: 2;
      }

      .m-box-method {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }

  .home-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;

    .shop {
      float: right;
      margin-right: 40px;
      margin-top: -200px;
    }

    .home-scan {
      width: 100%;
      height: 252px;
      // border-radius: 120px 120px 120px 120px;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      position: relative;
      background-color: #ffcb05;

      .nav-btn {
        width: 25%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding-top: 24px;
        .nav-label {
          font-size: 28px;
          font-family: Work Sans-Bold, Work Sans;
          font-weight: bold;
          color: #41200f;
          text-align: center;
          margin: 0;
        }
      }
      .scan-box {
        padding-top: 0;
        margin: 0 8%;
        .scan-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          position: relative;
          bottom: 86px;
        }
      }
    }
  }

  .shop {
    justify-content: end;
  }
}

.google-map {
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: height 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  height: 0;
}

.detailfade-enter-active,
.detailfade-leave-active {
  transition: opacity 0.5s;
}

.detailfade-enter,
.detailfade-leave-to .fade-leave-active {
  opacity: 0;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  overflow: hidden;

  .shop-content {
    height: 600px;
    width: 100%;
    position: absolute;
    z-index: 100;
    bottom: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0 -5px 10px #9e9b9b;
    background: #fff;

    .shop-title {
      display: flex;
      flex-direction: column;
      padding: 20px;
      background: #fff;
      border-radius: 20px;

      .title-info {
        padding: 0 30px;

        p {
          margin: 0 0 10px 0;
        }

        .return-num {
          margin-left: 20px;
          padding: 5px 10px;
          border: 1px solid #23aaf2;
          border-radius: 10px;
          color: #23aaf2;
        }

        .free-num {
          padding: 5px 10px;
          border: 1px solid #8dd35f;
          border-radius: 10px;
          color: #8dd35f;
        }

        .shop-time {
          h4 {
            margin: 20px 0;
            padding: 10px 0;
            border-bottom: 1px solid #cacdd1;
          }
        }
      }

      .shop-title-head {
        display: flex;
        flex-direction: row;
        align-items: center;

        h2 {
          margin: 10px 30px;
          padding: 20px 0;
          border-bottom: 1px solid #cacdd1;
        }

        .shop-tag {
          margin-left: 20px;
          font-size: 24px;
          padding: 5px 15px;
          border: 1px solid #cacdd1;
          border-radius: 10px;
        }

        .shop-tag-active {
          color: #8dd35f;
          border: 1px solid #8dd35f;
        }
      }
    }

    .address-detail {
      p {
        margin: 0;
        text-align: left;
        padding: 0 30px;
      }
    }
  }
}
</style>>
